import React from "react"
import { motion } from "framer-motion"
import { useColorMode } from "theme-ui"

import { svgVariants, pathVariants } from "../constants/animation"

const CanvaImage = () => {
    const [mode] = useColorMode()

    return (
        <motion.svg width="160" height="80%" viewBox="0 0 841.89 595.28"
        variants={svgVariants}
        initial="initial"
        animate={mode === "dark" ? "animated" : "selected"}
        whileHover={mode === "dark" ? "selected" : "animated"}
        transition={{ duration: 3 }}>
            <motion.path
               fill={mode === "dark" ? "#FFF" : "#6420FF"}
               stroke={mode === "dark" ? "#FFF" : "#6420FF"}
               strokeWidth="3px"
               strokeOpacity="1"
               variants={pathVariants}
               transition={{ duration: 1.5 }}
            d="M781.48,340.81c-1.24,0-2.37,0.8-2.96,2.48c-7.49,21.36-17.6,34.07-25.93,34.07c-4.78,0-6.72-5.33-6.72-13.69
	c0-20.92,12.53-65.29,18.84-85.52c0.73-2.45,1.21-4.64,1.21-6.5c0-5.88-3.21-8.76-11.17-8.76c-8.58,0-17.82,3.36-26.8,19.1
	c-3.1-13.88-12.49-19.94-25.6-19.94c-15.15,0-29.8,9.75-41.85,25.56c-12.05,15.81-26.22,21-36.88,18.44
	c7.67-18.77,10.52-32.79,10.52-43.2c0-16.32-8.07-26.18-21.11-26.18c-19.83,0-31.26,18.92-31.26,38.82
	c0,15.37,6.97,31.19,22.31,38.85c-12.85,29.07-31.62,55.36-38.74,55.36c-9.2,0-11.9-45.03-11.39-77.23
	c0.33-18.48,1.86-19.43,1.86-25.01c0-3.21-2.08-5.4-10.44-5.4c-19.5,0-25.53,16.51-26.44,35.46c-0.29,7.2-1.42,14.35-3.36,21.29
	c-8.14,29.07-24.94,51.12-35.9,51.12c-5.08,0-6.46-5.08-6.46-11.72c0-20.92,11.72-47.07,11.72-69.38c0-16.4-7.19-26.77-20.74-26.77
	c-15.96,0-37.07,18.99-57.04,54.56c6.57-27.24,9.28-53.61-10.19-53.61c-4.22,0.06-8.37,1.14-12.09,3.14
	c-2.56,1.19-4.12,3.83-3.94,6.65c1.86,28.99-23.37,103.27-47.29,103.27c-4.35,0-6.46-4.71-6.46-12.31
	c0-20.96,12.49-65.22,18.77-85.49c0.8-2.63,1.24-4.89,1.24-6.87c0-5.55-3.43-8.4-11.21-8.4c-8.55,0-17.82,3.25-26.77,18.99
	c-3.14-13.88-12.53-19.94-25.64-19.94c-21.51,0-45.54,22.75-56.09,52.4c-14.13,39.58-42.62,77.82-80.96,77.82
	c-34.8,0-53.17-28.96-53.17-74.71c0-66.06,48.49-120.03,84.46-120.03c17.2,0,25.42,10.96,25.42,27.75
	c0,20.34-11.36,29.8-11.36,37.54c0,2.37,1.97,4.71,5.88,4.71c15.63,0,34.03-18.37,34.03-43.42s-20.34-43.38-56.31-43.38
	c-59.45,0-119.27,59.82-119.27,136.46c0,60.98,30.09,97.76,82.13,97.76c35.49,0,66.5-27.61,83.22-59.82
	c1.9,26.69,14.02,40.64,32.5,40.64c16.43,0,29.73-9.79,39.88-26.99c3.91,18,14.28,26.8,27.75,26.8c15.45,0,28.37-9.79,40.68-27.97
	c-0.18,14.28,3.07,27.72,15.45,27.72c5.84,0,12.82-1.35,14.06-6.46c13.04-53.9,45.24-97.9,55.1-97.9c2.92,0,3.72,2.81,3.72,6.13
	c0,14.64-10.33,44.66-10.33,63.83c0,20.71,8.8,34.4,26.99,34.4c20.16,0,40.64-24.69,54.3-60.76c4.27,33.71,13.51,60.91,27.97,60.91
	c17.75,0,49.26-37.36,68.36-76.91c7.49,0.95,18.73,0.69,29.54-6.94c-4.6,11.65-7.3,24.39-7.3,37.14c0,36.7,17.53,47,32.61,47
	c16.4,0,29.69-9.79,39.88-26.99c3.36,15.52,11.94,26.77,27.72,26.77c24.69,0,46.12-25.23,46.12-45.94
	C786.55,344.13,784.21,340.81,781.48,340.81z M269.1,375.43c-9.97,0-13.88-10.04-13.88-25.01c0-26,17.78-69.42,36.59-69.42
	c8.22,0,11.32,9.68,11.32,21.51C303.14,328.9,286.23,375.43,269.1,375.43z M610.58,297.83c-5.95-7.08-8.11-16.72-8.11-25.31
	c0-10.59,3.87-19.54,8.51-19.54s6.06,4.56,6.06,10.92C617.04,274.53,613.24,290.05,610.58,297.83z M687.88,375.43
	c-9.97,0-13.88-11.54-13.88-25.01c0-25.09,17.78-69.42,36.74-69.42c8.22,0,11.14,9.6,11.14,21.51
	C721.88,328.9,705.26,375.43,687.88,375.43z">    
            </motion.path>
        </motion.svg>
    )
}

export default CanvaImage