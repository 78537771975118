import React from "react"
import { motion } from "framer-motion"
import { useColorMode } from "theme-ui"

import { svgVariants, pathVariants } from "../constants/animation"


const MLCImage = () => {
  const [mode] = useColorMode()

  return (
    <motion.svg
      width="90"
      height="100%" //"calc(90*271.18/414.47)"
      viewBox="0 0 414.47 271.18"
      variants={svgVariants}
      initial="initial"
      animate={mode === "dark" ? "animated" : "selected"}
      whileHover={mode === "dark" ? "selected" : "animated"}
      transition={{ duration: 3 }}
    >
      <motion.path
        fill-rule="evenodd"
        fill="#f19a24"
        stroke="#f19a24"
        strokeWidth="3px"
        strokeOpacity="1"
        variants={pathVariants}
        transition={{ duration: 1.5 }}
        d="M325.8,189.3c1.11-.26.9-1.42,0-1.19,0,0-192.3,26-286.88-12.12-17.93-7.22-14.92-18.32-4.87-27,.73-1.27,1-1.8,1-1.8C7.84,151.6-9.38,167.15,5.47,178c0,0,39.75,30,147.74,31.19C228.73,210,291.94,199.32,325.8,189.3Zm49.91-8.1c29-11.88,3.89-26.61,3.89-26.61s-1.25-2.35.48-1.76c48.46,7,35.64,34.62,21.84,43.61-88.22,57.54-297,45.7-388,8.23-.8-.33-.61-1.47.1-1.35C183.36,233.31,290.3,216.15,375.71,181.2ZM204.09,249.93c100.65-.67,149.53-19.54,149.53-19.54,6.36-1.58,3.95,5.26-.37,9.55,0,0-22.82,30.15-153.85,31.23-90.28.75-142-25.76-151.87-31.83-5.91-3.65-7.36-11.75-4-10.65C43.5,228.69,109.16,250.56,204.09,249.93Z"
      />
      <motion.path
        fill="#f5a523"
        stroke="#f5a523"
        strokeWidth="3px"
        strokeOpacity="1"
        variants={pathVariants}
        transition={{ duration: 1.5 }}
        d="M200.78,189c-10.83,0-21.55-.28-31.86-.82a497,497,0,0,1-59.25-6.52,368.75,368.75,0,0,1-49-11.86C53.45,167,47,155.74,43.91,145.86a42.19,42.19,0,0,1-1.39-5.51,112.43,112.43,0,0,1-1.69-18.53c0-1.92,0-3.82.06-5.66a111.86,111.86,0,0,1,4.86-28.58,108.39,108.39,0,0,1,12-26,114.32,114.32,0,0,1,19-22.47,136.76,136.76,0,0,1,43.3-26.36C136.83,6.3,155.58,2.24,175.83.7c5.26-.4,10.68-.63,16.11-.69h2.15a234.67,234.67,0,0,1,42.6,4,246.6,246.6,0,0,1,56.56,17.77A209.8,209.8,0,0,1,339.5,50a148.15,148.15,0,0,1,21.39,21.22A98.51,98.51,0,0,1,375,94a64.18,64.18,0,0,1,5.47,23.32c0,.37,0,.78,0,1.15a69.66,69.66,0,0,1-4.87,25.79A96.67,96.67,0,0,1,361.25,169l-.12.17-.21,0a570.3,570.3,0,0,1-59.84,12.32c-21.38,3.25-43.5,5.46-65.77,6.58-9.26.47-18.66.76-27.86.83C205.21,189,203,189,200.78,189Z"
      />
      <motion.path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3px"
        strokeOpacity="1"
        variants={pathVariants}
        transition={{ duration: 1.5 }}
        d="M165.46,147.35s-3.86-42.41-3.9-42.69h-.28a57.5,57.5,0,0,1-1.89,7.67l-11.51,35H130.73l-10.36-33.09a54.67,54.67,0,0,1-1.79-9.6h-.66c0,.24-.15,2.25-.15,2.25l-.56,7-3.39,33.43h-27L99.73,69.09h28.75s10.87,40.73,10.94,41h.5c0-.16.09-.53.09-.53a81.12,81.12,0,0,1,1.9-9.71l9-30.71H179.3l13.17,78.26Zm84.41.11H198V69.32h25.44v58.22h26.43ZM318,137.63c-7.66,7.55-18.41,11.06-29.17,11.06-26.81,0-38.92-20.22-38.92-39.47,0-20.23,13.5-40.77,38.92-40.77,11.39,0,22.26,4.06,30.13,12.15a35.12,35.12,0,0,1,6.28,8.83l-19.91,9.89c-1.91-4.47-5.53-12.45-15.54-12.45a14,14,0,0,0-10.09,4c-5.66,5.44-5.66,14-5.66,16.93,0,11.18,4.16,21.7,15.86,21.7,11.06,0,14.37-9.78,15.22-12.34L325.35,127A40.36,40.36,0,0,1,318,137.63Z"
      />
    </motion.svg>
  )
}

export default MLCImage
