module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"C:/Users/justi/dev/portfolio_2022/node_modules/gatsby-remark-images","id":"580fd6f0-a0f4-5cb7-ac28-2bea65acb525","name":"gatsby-remark-images","version":"6.11.1","modulePath":"C:\\Users\\justi\\dev\\portfolio_2022\\node_modules\\gatsby-remark-images\\index.js","pluginOptions":{"plugins":[]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"C:/Users/justi/dev/portfolio_2022/node_modules/gatsby-remark-highlight-code","id":"9ac5e00d-04d3-528d-bcbb-7d384a724d36","name":"gatsby-remark-highlight-code","version":"3.2.0","modulePath":"C:\\Users\\justi\\dev\\portfolio_2022\\node_modules\\gatsby-remark-highlight-code\\index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\justi\\dev\\portfolio_2022","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"prismPreset":{"color":"#d6deeb","backgroundColor":"#011627",".changed":{"color":"rgb(162, 191, 252)","fontStyle":"italic"},".deleted":{"color":"rgba(239, 83, 80, 0.56)","fontStyle":"italic"},".inserted,.attr-name":{"color":"rgb(173, 219, 103)","fontStyle":"italic"},".comment":{"color":"rgb(99, 119, 119)","fontStyle":"italic"},".string,.url":{"color":"rgb(173, 219, 103)"},".variable":{"color":"rgb(214, 222, 235)"},".number":{"color":"rgb(247, 140, 108)"},".builtin,.char,.constant,.function":{"color":"rgb(130, 170, 255)"},".punctuation":{"color":"rgb(199, 146, 234)"},".selector,.doctype":{"color":"rgb(199, 146, 234)","fontStyle":"italic"},".class-name":{"color":"rgb(255, 203, 139)"},".tag,.operator,.keyword":{"color":"rgb(127, 219, 202)"},".boolean":{"color":"rgb(255, 88, 116)"},".property":{"color":"rgb(128, 203, 196)"},".namespace":{"color":"rgb(178, 204, 214)"},".highlight":{"background":"hsla(0, 0%, 30%, .5)"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\justi\\dev\\portfolio_2022\\src\\components\\layout\\Layout.tsx"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
